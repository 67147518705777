<template>
  <b-card>
    <b-card-body>
          <b-overlay
              :show="loading"
              spinner-type="grow"
              spinner-variant="primary"
              :variant="`transparent`"
              :opacity="1"
              :blur="`2px`"
              rounded="sm"
              style="overflow-x: hidden !important;"
          >
            <p v-if="candidates.length === 0">
              Nothing to Show
            </p>
            <b-table
                v-else
                hover
                responsive
                :per-page="0"
                sticky-header="70vh"
                :current-page="currentPage"
                :items="candidates"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-desc="true"
            >
              <template #cell(name)="data">
                <b-link
                    :to="`/interviews/candidate/${data.item.hash}`"
                >
                  {{ data.value }}
                </b-link>
              </template>
              <template
                  #cell(interview_start_date)="data"
              >
                <div
                    id="interview_start_date"
                    style="display: flex; flex-direction: column;"
                >
                  <div>
                    {{ data.item.interview_start_date | moment("MM-DD-YYYY") }}
                  </div>
                </div>
              </template>
              <template
                  #cell(interview_due_date)="data"
              >
                <div
                    id="interview_due_date"
                    style="display: flex; flex-direction: column;"
                >
                  <div>
                    {{ data.item.interview_start_date | moment("MM-DD-YYYY") }}
                  </div>
                </div>
              </template>
              <!-- Column: category -->
            </b-table>
          </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCardHeader, BFormFile, BRow, BCol, BSpinner, BButton, BLink, BOverlay, BTable,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { analysError } from '@/_helpers/analys_error'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BFormFile,
    BRow,
    BCol,
    BSpinner,
    BButton,
    BLink,
    BOverlay,
    BTable,
  },
  data() {
    return {
      file: null,
      acceptedTypes: '.csv, .xlsx, .xls',
      loading: false,
      sampleDownload: `${process.env.VUE_APP_API_URL}/download/questions/import-example`,
      fields: [
        {
          key: 'name', label: 'Name', sortable: true, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'interview_start_date', label: 'Interview Start Date', sortable: true, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'interview_due_date', label: 'Interview Due Date', sortable: true, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'interviewer_name', label: 'Interviewer Name', sortable: true, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'position', label: 'Position', sortable: true, tdClass: 'text-center', thClass: 'text-center',
        }
      ],
      sortBy: 'name',
      sortDesc: false,
      candidates: [],
    }
  },
  beforeMount() {
    this.getCandidates()
  },
  methods: {
    getCandidates() {
      this.loading = true
      axios.get(`${process.env.VUE_APP_API_URL}/candidates`, {
        headers: {
          Authorization: `Bearer ${useJwt.getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        this.loading = false

        this.candidates = response.data.data
      }).catch(error => {
        this.loading = false
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
